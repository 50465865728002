.reports-btn {
  display: flex;
  gap: 10px;
}
.reports-btn button {
  padding: 5px 20px;
  background-color: transparent;
  border: 1px solid var(--t);
  color: var(--t);
  border-radius: 10px;
}
.reports-btn button.active {
  background-color: var(--t);
  color: #000;
}
.reports-container .reports {
  margin-top: 30px;
}
.reports-container .reports h5 {
  color: #fff;
}

@media screen and (max-width: 992px) {
  .reports-btn button {
    font-size: 12px;
  }
}
