.hero-container {
  padding: 50px;
  position: relative;
  padding-bottom: 0;
}
.hero-container img {
  scale: 1;
  transition: 0.3s all ease-out;
  border-radius: 15px;
}
.hero-container img:hover {
  scale: 1.02;
}
.image-slider {
  overflow-x: scroll;
  border: 1px solid red;
  padding: 0;
}
.slide {
  padding: 0;
}
.hero-slider-btns .icon {
  font-size: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100px;
  border: 2px solid var(--s);
  color: var(--s);
  padding: 5px;
  cursor: pointer;
}
.hero-slider-btns .icon:hover {
  background-color: var(--s);
  color: #000;
}

.hero-slider-btns .arrow-left {
  left: 3%;
}
.hero-slider-btns .arrow-right {
  right: 3%;
}

.slick-arrow {
  display: none !important;
}

/* .slick-dots {
  margin-bottom: 20px !important;
} */
/* .slick-dots li {
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 3px !important;
  background-color: rgba(255, 255, 255, 0.3);
}
.slick-dots li.slick-active {
  border: 1px solid var(--t) !important;
  background-color: var(--t) !important;
}
.slick-dots li button {
  color: transparent !important;
  background-color: transparent !important;
  opacity: 0 !important;
} */
.slick-dots li button:before {
  color: #ddd !important;
}
.slick-dots li.slick-active button:before {
  color: #fff !important;
}
@media screen and (max-width: 992px) {
  .hero-container {
    padding: 0;
    padding-top: 20px;
  }
}
