.bg-heading {
  padding: 40px 50px;
  background-color: var(--p);
  /* border: var(--border); */
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-heading h4 {
  font-size: 30px;
  text-align: center;
}
.terms-container {
  padding: 50px 200px;
  background: var(--bg);
  color: #fff;
}
.terms-container h5 {
  color: #fff;
  font-weight: 800;
  font-size: 18px;
  position: relative;
  text-transform: uppercase;
}

@media screen and (max-width: 992px) {
  .terms-container {
    padding: 50px 10px;
  }
}
