.register-container {
  padding: 50px 20px;
  background: var(--bg);
  color: #fff;
}
.form {
  padding: 20px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}
.form-fields {
  position: relative;
}
.form-fields .icon {
  position: absolute;
  top: 50%;
  right: 1.5%;
}
.form-fields .eyeicon {
  position: absolute;
  top: 50%;
  right: 10%;
}
.form-fields label {
  margin-bottom: 5px;
}
.form-fields input,
.form-fields select,
.form-fields textarea {
  border-radius: 0;
  padding: 10px 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.22);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #ddd;
}
.form-fields input::placeholder {
  color: #ddd;
}
.register-form h4 {
  font-weight: 400;
  color: #fff;
  text-align: start;
  margin: 0;
}
.register-form span {
  text-align: start;
  display: block;
  color: #ddd;
  margin-bottom: 20px;
}
.register-btn {
  width: 100%;
  background-color: var(--t);
  padding: 15px;
  color: #333;
  border-radius: 10px;
  font-weight: bold;
  &:hover {
    opacity: 0.9;
  }
}
.apply-code-btn {
  padding: 8px 15px;
  width: 180px;
  background-color: #222f3e;
  color: #333;
  outline: transparent;
  border: transparent;
  &:hover {
    opacity: 0.8;
  }
}
.hr-line {
  position: relative;
  margin-bottom: 20px;
}
.hr-line::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 0 !important;
}

/* .agreeterms {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}
.agreeterms input {
  margin: 0 !important;
}
.agreeterms span {
  font-size: 12px;
  margin: 0;
} */

.agreeterms {
  display: flex;
  align-items: center;
}

.custom-checkbox {
  opacity: 0; /* Make the checkbox transparent */
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.custom-checkbox::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  background-color: white;
  top: 0;
  left: 0;
}
.custom-checkbox:checked::before {
  content: "✔";
  color: green !important;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checked {
  color: green !important;
}

.form-check-input:checked {
  background-color: var(--t) !important;
  border-color: var(--t) !important;
}

.agreeterms label {
  font-size: 12px;
}

@media screen and (max-width: 992px) {
  .forgot-pass h6 {
    font-size: 12px;
  }
}
