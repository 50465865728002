@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none !important;
  text-decoration: none !important;
  font-family: "DM Sans", system-ui;
  font-optical-sizing: auto;
}
:root {
  --p: #000;
  --s: #171b1c;
  --t: #fbe047;
  --bg: linear-gradient(150deg, #312800, black 50%, #000 80%);
  --border: 1px solid rgba(255, 255, 255, 0.3);
}
ul {
  margin: 0 !important;
  padding: 0 !important;
}
.popp {
  font-family: "Poppins", sans-serif !important;
}
.caveat {
  font-family: "Caveat", cursive;
  font-weight: 500;
}

@media screen and (min-width: 993px) {
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: linear-gradient(#333, #000);
  }
}
