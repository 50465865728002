.single-promo-container {
  padding: 50px 200px;
  background-color: var(--p);
}
.single-promo-container .icon {
  background-color: var(--t);
  font-size: 40px;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
}
.single-promo {
  margin-top: 50px;
}
.single-promo img {
  width: 100%;
}
.single-promo-content {
  margin-top: 20px;
  color: #ddd;
}
.single-promo-container span {
  color: #bbbbbb;
  font-weight: 600;
}
.single-promo-container h2 {
  color: #fff;
  margin-top: 10px;
  font-weight: 600;
}
.single-promo-container .description {
  white-space: pre-wrap;
  color: #fff;
}

@media screen and (max-width: 992px) {
  .single-promo-container {
    padding: 30px 20px;
  }
}
