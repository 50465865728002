.footer-container {
  padding: 50px 20px;
  overflow: hidden;
  padding: 50px 100px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background-color: #000;
  color: #fff;
  position: relative;
}
.footer-container .footer-logo h6 {
  font-size: 30px;
}
.footer-container .footer-logo span {
  color: var(--t);
}
.footer-container .social-media-links {
  display: flex;
  gap: 8px;
}
.social-media-links a {
  color: var(--t);
}
.footer-container ul {
  padding: 0;
}
.footer-container ul li a {
  font-size: 16px;
  color: #fff;
  position: relative;
  cursor: pointer;
}
.footer-container ul li a:hover {
  opacity: 0.7;
}
.social-links {
  padding: 10px;
}
.social-icons .icon {
  font-size: 20px;
  margin: 0 5px;
  color: #333;
}

.mobile-sticky-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  padding: 10px 0;
  z-index: 99;
}
.mobile-sticky-footer ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.mobile-sticky-footer ul li {
  text-align: center;
  cursor: pointer;
}
.mobile-sticky-footer ul li a {
  color: #ebef29;
  font-size: 12px;
}
.mobile-sticky-footer li.active,
.mobile-sticky-footer li a.active {
  color: #26bd68;
}

.whatsapp-icon-cont {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
}
.whatsapp-icon-cont span {
  margin-right: 10px;
  background-color: #fff;
  padding: 5px 10px;
  color: #000;
  border-radius: 10px;
  font-size: 14px;
}
.whatsapp-icon-cont .icon {
  background-color: #27a6e6;
  color: #fff;
  border-radius: 100px;
  font-size: 60px;
  padding: 8px;
}

@media screen and (max-width: 992px) {
  .footer-container {
    padding: 50px 20px !important;
  }
}
@media screen and (max-width: 768px) {
  .footer-container {
    padding: 50px 20px;
  }
  .footer-para {
    width: 100%;
  }
  .whatsapp-icon-cont {
    right: 15px;
    bottom: 100px;
  }
  .whatsapp-icon-cont span {
    font-size: 12px;
    margin-right: 5px;
  }
  .whatsapp-icon-cont .icon {
    font-size: 50px;
  }
}
@media screen and (max-width: 640px) {
  .footer-container {
    padding: 50px 20px;
  }
  .copyright {
    font-size: 14px;
  }
}
@media screen and (max-width: 420px) {
  .footer-container {
    padding: 50px 20px;
  }
}
@media screen and (max-width: 375px) {
  .footer-container {
    padding: 50px 20px;
  }
}
