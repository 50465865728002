.event-container {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}
.event-img {
  width: 50%;
}
.event-img img {
  width: 100%;
  border: 2px solid #fff;
  border-radius: 10px;
}
.event-content {
  width: 50%;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.event-content h5 {
  margin: 0;
  font-size: 16px;
}
.event-content h1 {
  font-weight: bold;
  font-size: 50px;
}
.counterr span {
  padding: 20px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: var(--t);
  color: #000;
  font-weight: 500;
}

@media screen and (max-width: 786px) {
  .event-container {
    padding: 0;
  }
  .event-content h5 {
    font-size: 12px;
  }
  .event-content h1 {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .counterr span {
    padding: 10px;
    border-radius: 4px;
    margin-right: 5px;
    font-size: 14px;
  }
}
