.sidemenu-container {
  position: fixed;
  top: 0;
  right: -100%;
  width: 75%;
  height: 100vh;
  transition: all 0.3s ease;
  z-index: 20;
  z-index: 99999;
}
.sidemenu-container .logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;
  width: 100%;
}
.sidemenu-container .logo img {
  width: 150px;
}
.sidemenu-container .logo .icon {
  font-size: 35px;
}
.sidemenu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 200px;
  position: relative;
  background-color: var(--p);
  padding: 0 20px;
}
.sidemenu .close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 35px;
  color: #fff;
}
.sidemenu ul li {
  margin: 15px 0;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
.sidemenu ul li a {
  color: #fff;
  font-size: 16px;
}
.ul .icon {
  font-size: 25px;
}
.sidemenu .logout {
  color: var(--t);
  cursor: pointer;
}
.sidemenu .logout .icon {
  margin-right: 10px;
  font-size: 30px;
}
.sidemenu .logout:hover {
  color: #fff;
}

.sidemenu-img {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  object-fit: cover;
  border: 3px solid #26bd68;
  display: block;
  margin: 0 auto;
}
.active {
  right: 0;
}
.sidemenu-action-btn a {
  color: #fff;
  text-decoration: underline !important;
  margin-top: 40px !important;
}
.user {
  color: #fff;
}
.user span {
  font-size: 14px;
}
.user .icon {
  color: var(--t);
  font-size: 50px;
  margin-right: 10px;
}
.signin {
  background-color: var(--t);
  color: #000;
  width: 100%;
  padding: 10px;
  outline: transparent;
  border: transparent;
  border-radius: 10px;
}
