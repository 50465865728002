.productpagecontainer {
  background: var(--bg);
}
.p-info-container {
  padding: 50px;
  padding-top: 180px;
  padding-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  background-position: top;
  background-size: cover;
  align-items: flex-end;
  width: 100%;
  background: var(--bg);
}
.p-info-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.pro-img {
  z-index: 9999;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.pro-img img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 15px;
  margin-right: 15px;
}
.pro-img h2 {
  font-weight: 800;
  text-transform: uppercase;
  color: #fff;
  z-index: 9999;
}
.pro-img h6 {
  color: #fff;
  z-index: 9999;
}
.pro-img .features {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.pro-img .features button {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5px;
  border-radius: 10px;
  color: #000;
  outline: transparent;
  margin-right: 10px;
  border: transparent;
  .icon {
    margin-right: 5px;
  }
}
.instruction-image {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 9999;
  padding-right: 50px;
  padding-top: 20px;
}
.instruction-image button {
  background-color: #2587d2;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  outline: transparent;
  border: transparent;
  font-size: 15px;
}
.ins-popup {
  display: none;
}
.ins-popup.active {
  position: relative;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 400px;
  height: 400px;
  padding: 20px;
}
.ins-popup .close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  color: rgba(0, 0, 0, 0.5);
  border: 2px solid #fff;
  border-radius: 100px;
  font-size: 40px !important;
  cursor: pointer;
}

.ins-popup img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 10px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.8);
}
.package-details {
  display: flex;
  flex-wrap: wrap;
}
.package-container {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
}
.package-container .amount {
  width: 30%;
  border-radius: 10px;
  color: #fff;
  padding-left: 10px;
  background-color: #000;
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  padding: 10px;
  /* min-height: 150px; */
}
.package-container .amount .pack-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  row-gap: 10px;
}
.package-container .amount .pack-details .pack,
.package-container .amount .pack-details .price,
.package-container .amount .pack-details .image {
  width: 50%;
}
.package-container .amount .pack-details p {
  margin: 0;
}
.package-container .amount .pack-details .pack p:nth-child(1) {
  font-weight: 600;
  font-size: 16px;
}
.package-container .amount .pack-details .pack p:nth-child(2) {
  font-size: 12px;
}
.package-container .amount .pack-details .price p:nth-child(1) {
  font-size: 18px;
  font-weight: bold;
}
.package-container .amount .pack-details .price p:nth-child(2) {
  color: red;
  text-decoration: line-through !important;
}
.package-container .amount .image {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 50%;
}
.package-container .amount .image img {
  width: 50px;
  /* height: 50px; */
  /* object-fit: cover; */
}

.package-container .amount.active {
  border: 2px solid var(--t);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
  background-color: #111;
}
.order-info {
  width: 40%;
  padding: 50px;
  color: #fff;
}
.pack-info {
  background-color: #000;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  position: relative;
}
.pack-info .icon {
  cursor: pointer;
}
.helpimg {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 10px;
  z-index: 99;
  scale: 0;
  transition: 0.5s all ease;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.helpimg.active {
  scale: 1;
}
.helpimg img {
  border: 2px solid #fff;
  width: 50%;
}
.pack-info .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.player-tag {
  padding: 10px;
  width: 100%;
  outline: transparent;
  border-radius: 100px;
  background-color: #000;
}
.p-check-btn {
  outline: transparent;
  border: transparent;
  border-radius: 100px;
  background-color: var(--t);
  color: #000;
  font-weight: 800;
  padding: 10px 20px;
  width: 100%;
  margin-top: 10px;
}
.pro-desc-container {
  width: 60%;
  padding: 50px;
  color: #fff;
  padding-top: 0;
}
.pro-desc {
  background-color: #000;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
}
.pro-desc span {
  white-space: pre-wrap;
}

.product-info-content select {
  padding: 10px;
  margin: 10px 0;
  margin-bottom: 0;
  width: 50%;
  color: #fff;
}
.player-tag {
  padding: 10px;
  width: 100%;
  outline: transparent;
  border: 1px solid grey;
  margin-top: 10px;
}

/* ====================== PAYMENT  */
/* ====================== PAYMENT  */
/* ====================== PAYMENT  */
.payment-container {
  background-color: #000;
  color: #fff;
}
.payment-container p {
  color: #fff;
  font-weight: 600;
  margin: 0;
}
.payment {
  color: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
}
.payment .wallet.active {
  background-color: var(--t);
  color: #000;
}
.payment .upi.active {
  background-color: var(--t);
  color: #000;
}
.payment h4 {
  font-size: 20px;
}
.wallet,
.upi {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  h6 {
    font-weight: bold;
  }
}
.upi h6 {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 700;
}
.wallet {
  justify-content: space-between;
}
.wallet .icon {
  font-size: 30px;
}
.upi img {
  width: 100px;
  border-radius: 10px;
}
.coinimg {
  width: 35px;
}

/* ORDER SUCCESS CONTAINER */
.order-success-container {
  min-height: 100vh;
  background: var(--bg);
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-success-container .order-container {
  width: 40%;
  .order-item {
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 5px;
  }
}

/* LAODING CONTAINER  */
.loading-container {
  background: var(--bg);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: #fff;
  h4 {
    font-size: 20px;
  }
}

/* CONFIRM POPUP  */
.confirm-bs {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.confirm-popup {
  position: fixed;
  right: 10px;
  bottom: -100%;
  background-color: #fff;
  width: 30%;
  z-index: 99999;
  transition: 0.5s all ease;
  padding: 20px 25px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 20px 20px 0 0;
}
.confirm-popup.active {
  bottom: 10px;
}
.confirm-popup .icon {
  cursor: pointer;
}
.confirm-popup .price {
  text-align: center;
  margin-top: 20px;
}
.confirm-popup .price span {
  font-size: 12px;
}
.confirm-popup .price p {
  font-weight: bolder;
  font-size: 26px;
}
.confirm-popup button {
  padding: 10px;
}
.confirm-popup span {
  text-align: center !important;
  display: block;
  line-height: 16px;
}
.payment-secured {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  gap: 5px;
  font-size: 10px;
  text-transform: uppercase;
  color: grey;
}
.payment-secured .icon {
  color: green;
  font-size: 25px;
}
.confirmbtn {
  background-color: var(--t);
  color: #000;
  font-size: 14px;
}

@media screen and (max-width: 992px) {
  .p-info-container {
    padding: 10px;
    padding-top: 100px;
  }
  .pro-img h2 {
    font-size: 15px;
    margin: 0;
  }
  .pro-img img {
    width: 80px;
    height: 80px;
  }
  .pro-img .features button {
    font-size: 8px;
    padding: 2px;
    margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 5px;
    .icon {
      font-size: 10px;
    }
  }
  .ins-popup img {
    width: 100%;
    height: 100%;
  }
  /* package  */
  .package-container,
  .order-info,
  .pro-desc-container {
    width: 100%;
    padding-bottom: 0;
  }
  .pro-desc-container {
    margin-bottom: 50px;
  }
  .instruction-image {
    padding-right: 20px;
  }
  .instruction-image button {
    font-size: 10px;
  }
  .instruction-image .icon {
    font-size: 15px;
  }
}

@media screen and (max-width: 786px) {
  /* package  */
  .package-container,
  .order-info,
  .pro-desc-container {
    width: 100%;
    padding: 50px 20px;
    padding-bottom: 0;
    padding-top: 20px;
  }
  .order-success-container {
    min-height: 60vh;
  }
  .order-success-container .order-container {
    width: 100%;
  }
  .package-container .amount {
    width: 48%;
  }

  .package-container .amount .pack-details .pack {
    width: 100%;
  }
  .package-container .amount .pack-details .image {
    width: 50%;
    order: 3;
  }
  .package-container .amount .image img {
    width: 40px;
  }
  .package-container .amount .pack-details .price {
    width: 50%;
  }
  .loading-container {
    min-height: 70vh;
    h4 {
      font-size: 15px;
    }
  }
  .confirm-popup {
    width: 100%;
    padding: 40px 20px;
  }
  .confirm-popup.active {
    right: 0;
    bottom: 0px;
  }
  .confirm-popup .logo img {
    width: 40%;
  }
}

/* ================================================================================ */

/* ======================= EFFECT */
.area {
  position: relative;
}

/* ========================================================= BG EFFECT LOADER ON ORDER PROCESS */
.spinner {
  font-size: 28px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
}

.spinner.center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.spinner .spinner-blade {
  position: absolute;
  left: 0.4629em;
  bottom: 0;
  width: 0.074em;
  height: 0.2777em;
  border-radius: 0.0555em;
  background-color: transparent;
  -webkit-transform-origin: center -0.2222em;
  -ms-transform-origin: center -0.2222em;
  transform-origin: center -0.2222em;
  animation: spinner-fade9234 1s infinite linear;
}

.spinner .spinner-blade:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.spinner .spinner-blade:nth-child(2) {
  -webkit-animation-delay: 0.083s;
  animation-delay: 0.083s;
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.spinner .spinner-blade:nth-child(3) {
  -webkit-animation-delay: 0.166s;
  animation-delay: 0.166s;
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.spinner .spinner-blade:nth-child(4) {
  -webkit-animation-delay: 0.249s;
  animation-delay: 0.249s;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.spinner .spinner-blade:nth-child(5) {
  -webkit-animation-delay: 0.332s;
  animation-delay: 0.332s;
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.spinner .spinner-blade:nth-child(6) {
  -webkit-animation-delay: 0.415s;
  animation-delay: 0.415s;
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.spinner .spinner-blade:nth-child(7) {
  -webkit-animation-delay: 0.498s;
  animation-delay: 0.498s;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.spinner .spinner-blade:nth-child(8) {
  -webkit-animation-delay: 0.581s;
  animation-delay: 0.581s;
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.spinner .spinner-blade:nth-child(9) {
  -webkit-animation-delay: 0.664s;
  animation-delay: 0.664s;
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.spinner .spinner-blade:nth-child(10) {
  -webkit-animation-delay: 0.747s;
  animation-delay: 0.747s;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.spinner .spinner-blade:nth-child(11) {
  -webkit-animation-delay: 0.83s;
  animation-delay: 0.83s;
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.spinner .spinner-blade:nth-child(12) {
  -webkit-animation-delay: 0.913s;
  animation-delay: 0.913s;
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

@keyframes spinner-fade9234 {
  0% {
    background-color: #fff;
  }

  100% {
    background-color: transparent;
  }
}
