.wallet-dash {
  display: flex;
  padding: 30px 0px;
  padding-top: 0;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}
.wallet-dash-card {
  width: 100%;
  padding: 20px;
  cursor: pointer;
  border-radius: 15px;
  color: #fff;
  .icon {
    color: var(--t);
  }
}
.txn {
  background-color: var(--p);
  border: var(--border);
  display: flex;
  justify-content: space-between;
}
.txn .icon {
  color: var(--t);
}
.wallet-dash-card-one {
  background-color: var(--p);
  border: var(--border);
  color: #fff;
  text-align: center;
}
.wallet-dash-card-one .icon {
  color: var(--t);
}
.wallet-dash-card-one p {
  font-size: 22px;
  font-weight: bold;
}
.wallet-dash-card-one span {
  font-size: 14px;
}
.add-money {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.bar-img {
  width: 95%;
  margin-top: 20px;
  border: 2px solid var(--t);
}
.txn-details {
  width: 50%;
}
.bar-img img {
  width: 100%;
}
.pay-btn {
  width: 100%;
  background-color: var(--t);
  padding: 10px;
  margin-top: 10px;
  border: transparent;
  outline: transparent;
  &:hover {
    opacity: 0.8;
  }
}

/* COIN CONTAINER  */
.add-coin-container h4 {
  margin-bottom: 20px;
  color: #fff;
}
.add-coin-container .buy-now {
  padding: 15px;
  margin-top: 10px;
}
.coin-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  gap: 5px;
  row-gap: 10px;
}
.coin {
  width: 30%;
  background-color: #fff;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
}
.coin p:nth-child(1) {
  margin: 0;
  font-weight: bold;
}
.coin p:nth-child(2) {
  margin: 0;
}
.coin:hover {
}
.coin.active {
  border: 3px solid var(--t);
  background-color: var(--t);
}
.backbtn {
  cursor: pointer;
  color: var(--t);
  margin-bottom: 15px;
  span,
  .icon {
    color: var(--t);
    scale: 1;
    transition: 0.1s all ease;
  }
}
.backbtn:hover {
  .icon {
    scale: 1.2;
  }
}

@media screen and (max-width: 992px) {
  .wallet-dash-card {
    width: 100%;
  }
  .bar-img {
    width: 100%;
  }
  .txn-details {
    width: 100%;
  }
}
@media screen and (max-width: 786px) {
  .coin {
    width: 48%;
  }

  .add-coin-container .buy-now {
    padding: 15px;
    margin-top: 10px;
    font-size: 16px;
  }
}
