.explore-products-container {
  padding: 70px 50px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
}
.popular-games {
  width: 100%;
  padding: 20px;
  background-color: var(--p);
  border-radius: 15px;
  background: rgba(6, 6, 6, 0.6);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.titlee {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.titlee h5 {
  text-transform: uppercase;
  font-weight: 800;
}
.titlee span {
  cursor: pointer;
  text-decoration: underline;
}
.games-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 30px;
}
.game {
  width: 12%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: #000;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  margin-top: 50px;
  padding-top: 90px;
  gap: 10px;
}
.game img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 15px;
  margin-top: -25px;
  border: 2px solid grey;
}
.game p {
  color: #ddd;
  font-size: 12px;
  text-align: center;
  margin: 0;
}
.game h5 {
  font-size: 14px;
  text-align: center;
  margin: 0;
}
.buy-now {
  color: var(--t);
  padding: 5px 30px;
  font-size: 14px;
  border-radius: 100px;
  margin-top: 5px;
  outline: transparent;
  border: 2px solid var(--t);
  background-color: transparent;
  transition: 0.3s all ease;
}
.buy-now:hover {
  background-color: var(--t);
  color: #000;
}
.buy-now .icon {
  font-size: 14px;
}

/* FILTER BTNS  */
.filter-btns {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: scroll;
}
.filter-btns button {
  outline: transparent;
  border: transparent;
  margin-right: 5px;
  padding: 6px 25px;
  border-radius: 10px;
  min-width: fit-content;
  background-color: transparent;
  border: 1px solid #fff;
}
.filter-btns button.active {
  background-color: var(--t);
  border: 1px solid var(--t);
  color: #000;
}

/* flsh sale  */
.flash-sale-container {
  padding: 50px 100px;
}
.flash-sale-container h2 {
  color: #fff;
}
.flash-sale-container .icon {
  color: var(--a);
  font-size: 40px;
}
.flash-salee {
  padding-right: 20px;
  color: #000;
}
.fsale {
  background: linear-gradient(to bottom, var(--p), var(--t));
  color: #fff;
  border: 2px solid #fff;
  padding: 15px;
  display: flex;
  gap: 15px;
  border-radius: 10px;
}
.fimg {
  width: 30%;
}
.fimg img {
  width: 100%;
  border: 2px solid #fff;
  border-radius: 10px;
}
.fcontent {
  width: 70%;
}
.fcontent p {
  margin: 0;
  font-size: 12px;
}
.fcontent h5 {
  font-size: 18px;
}
.fcontent .price {
  outline: transparent;
  border: transparent;
  display: block;
  text-align: center;
  border-radius: 5px;
  margin-top: 10px;
  padding: 4px 20px;
  background-color: var(--s);
  color: #000;
  position: relative;
  font-weight: bold;
}
.fcontent .ogPrice {
  text-decoration: line-through !important;
  color: red;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 600;
}
.fcontent .stock {
  width: 100%;
  font-size: 14px;
  background: linear-gradient(to right, red, yellow);
  margin-top: 15px;
  border-radius: 4px;
  padding-left: 10px;
}
.flash-slider-btns {
  position: relative;
  .left,
  .right {
    position: absolute;
    top: -100px;
    border: 1px solid var(--a);
    border-radius: 100px;
    cursor: pointer;
    transition: 0.3s all ease;
    &:hover {
      background-color: var(--a);
      color: #fff;
    }
  }
  .left {
    left: -50px;
  }
  .right {
    right: -50px;
  }
}

@media screen and (max-width: 992px) {
  .explore-products-container {
    padding: 50px 20px;
    flex-wrap: wrap;
  }
  .popular-games {
    width: 100%;
  }
  .game {
    width: 28%;
  }
}
@media screen and (max-width: 600px) {
  .popular-games h5,
  .popular-games span {
    font-size: 15px;
  }
  .games-list {
    gap: 20px;
  }
  .game {
    width: 30%;
    gap: 5px;
  }
  .game p {
    font-size: 10px;
    margin-bottom: 0;
    margin-top: 10px;
  }
  .game h5 {
    font-size: 12px;
  }
  .buy-now {
    font-size: 10px;
    gap: 5px;
    .icon {
      font-size: 10px;
    }
  }
  .filter-btns button {
    font-size: 12px;
  }
}
@media screen and (max-width: 500px) {
  .explore-products-container {
    padding: 50px 20px;
    flex-wrap: wrap;
  }
  .popular-games {
    width: 100%;
    padding: 10px;
  }
  .popular-games h5,
  .popular-games span {
    font-size: 15px;
  }
  .games-list {
    gap: 10px;
  }
  .game {
    width: 31%;
    padding: 10px;
    padding-top: 30px;
  }
  .game img {
    width: 50px;
    height: 50px;
  }
  .game h5 {
    font-size: 11px;
  }
  .buy-now {
    padding: 5px 20px;
    font-size: 12px;
  }
  .buy-now .icon {
    font-size: 12px;
  }
}

@media screen and (max-width: 400px) {
  .buy-now {
    font-size: 10px;
    gap: 5px;
    .icon {
      font-size: 10px;
    }
  }
}

@media screen and (min-width: 350px) {
  .buy-now {
    font-size: 8px;
    gap: 5px;
    .icon {
      font-size: 8px;
    }
  }
}
