.dashboard-container {
  padding: 30px 50px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: var(--bg);
}
.dashboard-menu {
  width: 30%;
  padding: 20px;
  background-color: var(--p);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.dash {
  background-color: var(--p) !important;
  color: #fff;
}
.dash span {
  color: #fff !important;
}
.dashboard-menu ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dashboard-menu ul li {
  padding: 5px;
  padding-left: 15px;
  border-radius: 15px;
  transition: 0.5s all ease;
  color: #fff;
  cursor: pointer;
}
.dashboard-menu ul li:hover {
  background-color: var(--s);
}
.dashboard-menu ul li.active {
  background-color: var(--s);
}

/* CONTENT  */
.dashboard-content {
  width: 70%;
  padding: 0 20px;
}
.user-dashboard {
  display: flex;
  gap: 20px;
}
/* =============== MEDIA QUERY ===============  */
@media screen and (max-width: 992px) {
  .dashboard-container {
    padding: 0;
  }
  .dashboard-menu {
    width: 100%;
  }
  .dashboard-content {
    width: 100%;
    margin-top: 30px;
    overflow-x: scroll;
    padding-bottom: 50px;
  }
  .user-dashboard {
    flex-direction: column;
  }
}
